import React from 'react';
import { CardContainer, CardText, CardValue } from './CardStyles';

const Card = ({ title, value }) => (
  <CardContainer>
    <CardText>{title}</CardText>
    <CardValue>{value}</CardValue>
  </CardContainer>
);

export default Card;
