import React, { useState } from "react";
import evaApi from "../../services/api/evaApi";
import {InputBoxContainer, TextInput, SendButton} from './InputBox.js'

const InputBox = ({ clientId, token, session, setMessages, setIsTyping, setBotTyping, habilitaContexto, habilitaEmail, habilitaZendesk }) => {
    const [input, setInput] = useState("");
  
    const handleInputChange = (e) => {
      setInput(e.target.value);
    };
  
    const handleSendMessage = async () => {
      if (input.trim() === "") return;
  
      const userMessage = { sender: "user", text: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInput("");
      setIsTyping(true);
  
      const body = {
        query: input,
        memory: "{}",
        searchdocs: true,
        temperature: 0.1,
        template: habilitaContexto ? "template_contexto" : "template_internet",
        client_id: clientId,
        email: habilitaEmail,
        zendesk: habilitaZendesk,
        sessionid: session,
        username: `WEB {{${clientId}}}`,
        cl: process.env.REACT_APP_CL,
        engine:process.env.REACT_APP_ENGINE
      };
  
      const response = await evaApi.askai(body, token);
      
      const data = response.data;
      const botMessage = data.message;
  
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: botMessage },
      ]);
      setIsTyping(false);
      setBotTyping(false);
    };
  
    return (
      <InputBoxContainer>
        <TextInput
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Digite sua mensagem..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        <SendButton onClick={handleSendMessage}>Enviar</SendButton>
      </InputBoxContainer>
    );
  };
  
  export default InputBox;