import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  background-color: transparent;
  z-index: 1005;
  position: absolute;
  width: calc(100% - 6rem);
  gap: 1.5rem;

  &.backButton {
    width: calc(100vw - 6rem) !important;
  }

  &.no-width {
    width: auto !important;
  }

  @media (max-width: 1024px) {
    padding: 1rem 2rem;
  }

  @media (max-width: 768px) {
    width: calc(100% - 1rem) !important;
    padding: 1rem !important;
    background-color: #fff;
    left: 35%;

    &.backButton {
      width: calc(100vw - 1rem) !important;
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    left: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  color: #4f9deb;

  @media (min-width: 1500px) {
    font-size: 2.1rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const Logo = styled.img`
  height: 1.8em;

  @media (min-width: 1500px) {
    height: 26px;
  }

  @media (max-width: 1024px) {
    height: 22px;
  }

  @media (max-width: 768px) {
    height: 20px;
  }

  @media (max-width: 600px) {
    height: 16px !important;
  }
`;

export const FooterLogo = styled.div`
  i {
    font-size: 11px;
    color: #4f9deb;

    @media (min-width: 1500px) {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 1024px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 9px;
  }

  @media (max-width: 600px) {
    display: none !important;
  }
`;

export const BackButton = styled.button`
  border: none;
  background-color: transparent;
  color: #4f9deb;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  @media (min-width: 1500px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
