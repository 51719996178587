import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  FileList,
  TotalFiles,
  FileListHeader,
  FileItem,
  FileName,
  FileDate,
  DeleteButton,
  FileActions
} from './FileListModal'

const FileListModal = ({ isOpen, onClose, files, onDeleteFile }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Arquivos Carregados</ModalTitle>
          <CloseButton onClick={onClose}>X</CloseButton>
        </ModalHeader>
        <TotalFiles>Total de arquivos carregados: {files.length}</TotalFiles>
        <FileListHeader>
          <span>Nome do arquivo</span>
          <span>Data de upload</span>
        </FileListHeader>
        <FileList>
          {files.length > 0 ? (
            files.map((fileObj, index) => (
              <FileItem key={index}>
                <FileName>{fileObj.file}</FileName>
                <FileActions>
                  <FileDate>{fileObj.date}</FileDate>
                  <DeleteButton onClick={() => onDeleteFile(fileObj.file)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </DeleteButton>
                </FileActions>
              </FileItem>
            ))
          ) : (
            <FileItem>Nenhum arquivo carregado</FileItem>
          )}
        </FileList>
      </ModalContent>
    </ModalOverlay>
  );
};

export default FileListModal;
