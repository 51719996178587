import React, { useState, useEffect, useRef } from "react";
import evaApi from "../../services/api/evaApi";
import fileApi from "../../services/api/fileApi";
import Header from "../Header/Header.jsx";
import Sidebar from "../Sidebar/Sidebar.jsx";
import MessageList from "../MessageList/MessageList.jsx";
import InputBox from "../InputBox/InputBox.jsx";
import { MainContainer, Content, ChatContainer, UploadNotification, TypingIndicator, MenuButton } from './Chatbot.js'

const Chatbot = ({ token, clientId, onLogout, sessionId, onSessionId }) => {
  const [session, setSession] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [currentBotMessage, setCurrentBotMessage] = useState("");
  const [botTyping, setBotTyping] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const messagesEndRef = useRef(null); 
  const shouldLog = useRef(true);
  const [uploadNotification, setUploadNotification] = useState(null);
  const [habilitaImagens, setHabilitaImagens] = useState(false);
  const [habilitaEmail, setHabilitaEmail] = useState(false);
  const [habilitaZendesk, setHabilitaZendesk] = useState(false);
  const [habilitaContexto, setHabilitaContexto] = useState(true);
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 10 * 60 * 1000;

  const resetTimer = () => {
    if (inactiveTimerRef.current) {
      clearTimeout(inactiveTimerRef.current);
    }
    inactiveTimerRef.current = setTimeout(() => {
      bye();
      setTimeout(onLogout, 60000)
    }, inactivityLimit);
  };
  
  const bye = async () => {
    setMessages([]);
    setIsTyping(true);

    const body = {
      query:
        "Se despeça de maneira informal para o usuário falando que por ter passado 10 min de atividade você encerrará o atendimento",
        memory: "{}",
        searchdocs: true,
        temperature: 0.1,
        template: habilitaContexto ? "template_contexto" : "template_internet",
        client_id: clientId,
        email: habilitaEmail,
        zendesk: habilitaZendesk,
        sessionid: session,
        username: `WEB {{${clientId}}}`,
        cl: process.env.REACT_APP_CL,
        engine:process.env.REACT_APP_ENGINE
    };

    try {
      const response = await evaApi.askai(body, token);
      const data = response.data;
      typeMessage(data.message);
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                onLogout();
            } else {
                const errorMessage = error.response.data.message || "Ocorreu um erro inesperado.";
                handleNotification(errorMessage);
            }
        } else {
            handleNotification("Erro de rede. Verifique sua conexão.");
        }
    }
  };
 
  useEffect(() => {
    resetTimer();
    
    return () => {
      clearTimeout(inactiveTimerRef.current); 
    };
  }, []);

  const handleHabilitaImagens = () => {
    setHabilitaImagens(!habilitaImagens);
  };

  const handleHabilitaEmail = () => {
    setHabilitaEmail(!habilitaEmail);
  };

  const handleHabilitaZendesk = () => {
    setHabilitaZendesk(!habilitaZendesk);
  };

  const handleHabilitaContexto = () => {
    setHabilitaContexto(!habilitaContexto);
  };

  const handleNotification = (value) => {
    setUploadNotification(value);
    setTimeout(() => setUploadNotification(null), 3000);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentBotMessage]);

  useEffect(() => {
    if (session === "") {
      if (sessionId) {
        const fetchSession = async () => {
          try {
            const response = await evaApi.getSession(sessionId);
            const sessionData = response.data.messages.sort((a, b) =>
              a.dat > b.data ? 1 : b.data > a.data ? -1 : 0
            );
            setSession(sessionId);
            setMessages([]);
            for (let i = 0; i < sessionData.length; i++) {
              const messageList = [];
              if (i === 0) {
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                });
              } else {
                messageList.push({
                  sender: "user",
                  text: sessionData[i]["pergunta"],
                });
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                });
              }
              setMessages((prevMessages) => [...prevMessages, ...messageList]);
            }
          } catch (error) {
            console.error("Error fetching session:", error);
            const errorMessage = error.response.data.message || "Ocorreu um erro inesperado.";
            handleNotification(errorMessage);
          }
        };
        fetchSession();
      } else if (shouldLog.current) {
        shouldLog.current = false;

        const grettings = async () => {
          setMessages([]);
          setIsTyping(true)

          const body = {
            query:
              "Se apresente de maneira informal para o usuário falando sobre é um assistente virtual especializado da empresa e irá ajudá-lo",
            memory:
              '{"role": "system", "content": "Você é um representante comercial querendo vender um produto ou uma franquia. Lembre todas as perguntas que o humano fizer"}',
            searchdocs: false,
            temperature: 0.9,
            template: "template_saudacao",
            client_id: clientId,
            email: false,
            zendesk: false,
            sessionid: "",
            username: `WEB {{${clientId}}}`,
            cl: process.env.REACT_APP_CL,
            engine:process.env.REACT_APP_ENGINE
          };
          try {
            const response = await evaApi.askai(body, token);
            const data = response.data;
            typeMessage(data.message);
            setSession(data.sessionid);
            onSessionId(data.sessionid);
          } catch (error) {
              if (error.response) {
                  if (error.response.status === 401 || error.response.status === 403) {
                      onLogout();
                  } else {
                      const errorMessage = error.response.data.message || "Ocorreu um erro inesperado.";
                      handleNotification(errorMessage);
                  }
              } else {
                  handleNotification("Erro de rede. Verifique sua conexão.");
              }
          }
        };

        grettings();
      }
    }
  }, [session, sessionId, clientId, token, onSessionId, onLogout]);

  const handleNewChat = () => {
    shouldLog.current = true;
    setSession("");
    onSessionId("");
  };

  const typeMessage = (message) => {
    setBotTyping(true);
    setCurrentBotMessage(""); 
    let index = -1;
  
    const typingInterval = setInterval(() => {
      setCurrentBotMessage((prev) => prev + message[index]);
      index++;
  
      if (index === message.length) {
        clearInterval(typingInterval);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: message },
        ]);
        setBotTyping(false);
        setIsTyping(false);
      }
    }, 1);
  };

  const handleFileUpload = async (files) => {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        await fileApi.uploadFile(file, clientId);
      }
      let ret = await fileApi.processFile(clientId, session);
      if (ret.status === 200) {
        handleNotification("Arquivo processado com sucesso!");
      }
    }
  };
  

  return (
    <MainContainer>
      {!sidebarOpen && (
        <Header onLogout={onLogout} showUserMenu={true} />
      )}
      {uploadNotification && <UploadNotification>{uploadNotification}</UploadNotification>}
      <Content>
        <MenuButton onClick={() => setSidebarOpen(!sidebarOpen)}>
        </MenuButton>
        <Sidebar 
          clientId={clientId} 
          token={token} 
          onNewChat={handleNewChat} 
          onFileUpload={handleFileUpload}
          onHabilitaContexto={handleHabilitaContexto}
          onHabilitaEmail={handleHabilitaEmail}
          onHabilitaImagens={handleHabilitaImagens}
          onHabilitaZendesk={handleHabilitaZendesk}
          habilitaContexto={habilitaContexto}
          habilitaEmail={habilitaEmail}
          habilitaImagens={habilitaImagens}
          habilitaZendesk={habilitaZendesk}
          issidebaropen={sidebarOpen} 
          onClose={() => setSidebarOpen(false)}
          isTyping={isTyping}
        />
        <ChatContainer>
          <MessageList
            messages={messages}
            isTyping={isTyping}
            botTyping={botTyping}
            messagesEndRef={messagesEndRef}
          />
          {isTyping && !botTyping && (
            <TypingIndicator /> 
          )}
          <InputBox
            clientId={clientId}
            token={token}
            session={session}
            setMessages={setMessages}
            setIsTyping={setIsTyping}
            setBotTyping={setBotTyping}
            habilitaContexto={habilitaContexto}
            habilitaEmail={habilitaEmail}
            habilitaZendesk={habilitaZendesk}
          />
        </ChatContainer>
      </Content>
    </MainContainer>
  );
};

export default Chatbot;
