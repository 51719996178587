import styled from "styled-components";

export const InputBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  background-color: #fff;
`;

export const TextInput = styled.input`
  flex: 1;
  padding: 10px 120px 10px 10px;
  font-size: 12px;
  border-radius: 30px;
  border: 1px solid rgba(79, 157, 235, 0.5);
  width: calc(100% - 5rem);
  height: 40px;
  transition: border-color 0.3s ease;
  font-size: 1rem;
  &:focus {
    border: 1px solid #4f9deb;
    outline: none;
  }
`;

export const SendButton = styled.button`
  padding: 10px 20px;
  background-color: #4f9deb;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
`;
