import styled from "styled-components";

export const UserProfileContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 5rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #4f9deb;
  }

  @media (min-width: 1500px) {
      font-size: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      padding: 1.2rem;
      width: calc(100vw - 2.4rem);

      h2{
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      }
    }
`;

export const UserProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 10px;
    width: calc( 100% - 20px);
  }
`;

export const UserProfileInfo = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  width: 800px;
  
  @media (min-width: 1500px) {
    width: calc(80vw);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  & > div {
    width: calc(50% + 3rem);

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 0.5rem;
  color: #4f9deb;
  width: 80vw;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 16px;

    @media (min-width: 1500px) {
      font-size: 1.4rem;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  input,
  textarea {
    padding: 1rem 1.5rem;
    border: 1px solid #4f9deb50;
    border-radius: 2rem;
    font-size: 14px;
    background-color: #f0f0f050;
    width: calc(100% - 3rem);
  }

  input {
    width: calc(100% - 1rem);
  }

  input[type="checkbox"] {
    width: auto;
  }

    &:disabled {
      background-color: #f0f0f050;
    }

    @media (min-width: 1500px) {
      padding: 1.3rem 1.8rem;
      font-size: 1.1rem;
      border-radius: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 12px;
      width: 100%;
      margin-bottom: 1rem;

      input{
      width: calc(100% - 3rem);
      }
      

      textarea {
        width: calc(100% - 3rem);
        resize: vertical;
      }
    }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 2rem;

  button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    
  }
    @media (min-width: 1500px) {
      button{
      font-size: 1.35rem;
      height: 50px;
      }
    }

    @media (max-width: 768px) {
      button{
      padding: 10px 15px;
      font-size: 12px;
      }
    }

  button:first-child {
    background-color: #4f9deb;
    color: white;
    width: 300px;
    border-radius: 25px;
    height: 40px;

    &:hover {
      background-color: #4f9deb;
    }

    
  }
    @media (max-width: 768px) {
      button:first-child {
      width: 80%;
      }
    }

  button:last-child {
    background-color: white;
    color: #4f9deb;
    border: 1px solid #4f9deb;
    width: 300px;
    border-radius: 25px;
    height: 40px;

    &:hover {
      background-color: #4f9deb;
      color: white;
    }
  }

  
`;
