import React, { useState } from "react";
import { ModalContainer, DragDropArea, UploadButton, ModalContent, FileList, ModalHeader, ConfirmUpload } from "./FileUploadModal.js";
import { getNewId } from "../../services/utils/idService";

const FileUploadModal = ({ isOpen, onClose, onFileUpload, multiple = false, id = getNewId() }) => {
  const [dragActive, setDragActive] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]); // Store selected files
  const [inputKey, setInputKey] = useState(Date.now()); 
  const inputRef = React.useRef(null); // Reference for the file input

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFiles([...selectedFiles, ...Array.from(e.dataTransfer.files)]); // Store the files in state
      e.dataTransfer.clearData();
      setInputKey(Date.now());
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)]); // Store the files in state
      setInputKey(Date.now());
    }
  };

  const handleConfirmUpload = () => {
    if (selectedFiles.length > 0 && onFileUpload) {
      onFileUpload(selectedFiles); // Trigger the actual upload
      setSelectedFiles([]); // Clear the selected files
      onClose(); // Close the modal
    }
  };

  const handleRemoveFile = (fileIndex) => {
    setSelectedFiles(selectedFiles.filter((_, index) => index !== fileIndex)); // Remove file by index
  };

  const handleClickDragArea = () => {
    inputRef.current.click(); // Trigger the click on the hidden file input
  };

  return isOpen ? (
    <ModalContainer>
      <ModalContent>
        <ModalHeader>
          <h2>Upload de Arquivos</h2>
          <button onClick={onClose}>X</button>
        </ModalHeader>
        
        <>
            <span>Tamanho máximo por arquivo: 50MB</span>
            <DragDropArea
            onDragOver={(e) => { e.preventDefault(); setDragActive(true); }}
            onDragLeave={(e) => { e.preventDefault(); setDragActive(false); }}
            onDrop={handleDrop}
            onClick={handleClickDragArea} // Trigger file input on click
            >
            {dragActive ? (
                <p>Solte seus arquivos aqui</p>
            ) : (
                <p>Arraste seus arquivos ou clique para selecionar</p>
            )}
            <input
                key={inputKey}
                type="file"
                ref={inputRef} // Ref for the input
                multiple={multiple}
                onChange={handleFileSelect}
                style={{ display: "none" }}
                id={id}
            />
            </DragDropArea>
            <p>ou</p>
            <UploadButton onClick={handleClickDragArea}>
            Buscar nos Meus Arquivos
            </UploadButton>
        </>
        

        {/* Display the list of selected files */}
        {selectedFiles.length > 0 && (
          <>
            <FileList>
              {selectedFiles.map((file, index) => (
                <div key={index}>
                  {file.name} <button style={{backgroundColor: 'white', border: 'none'}} onClick={() => handleRemoveFile(index)}>Remover</button>
                </div>
              ))}
            </FileList>
            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
            <ConfirmUpload  onClick={handleConfirmUpload} disabled={selectedFiles.length === 0} >
            Confirmar Upload
            </ConfirmUpload>
            </div>
            
          </>
        )}
      </ModalContent>
    </ModalContainer>
  ) : null;
};

export default FileUploadModal;
