import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  gap: 1rem;

  & > :first-child {
    display: flex;
    gap: 1rem;
  }

  & > :nth-child(2) {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 765px) {
    flex-direction: column;
    gap: 1rem;
    

    & > :first-child {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  & > :nth-child(2) {
    display: flex;
    gap: .5rem;
  }
  }

  @media (min-width: 1500px) {
    gap: 2rem;
  }
`;

export const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ active }) => (active ? "#4f9deb" : "white")};
  color: ${({ active }) => (active ? "white" : "#4f9deb")};
  border: 1px solid #4f9deb;
  border-radius: 1.5rem;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ active }) => (active ? "#3b8bc2" : "#f0f0f0")};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1500px) {
    font-size: 16px;
    padding: 1rem 2rem;
  }
`;
