import "./css/Input.css";

const DateInput = ({ id, label, value, onChange }) => (
  <div className="date-input-container">
    <label htmlFor={id} className="date-input-label">
      {label}
    </label>
    <input
      type="date"
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="date-input"
    />
  </div>
);

export default DateInput;
