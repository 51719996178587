// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-menu-container {
  position: relative;
  display: inline-block;
}

.user-icon-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
}

.user-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #e4e4e4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1000;
  width: 14.5rem;
}

.user-menu-item {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  font-size: 14px;
  transition: background-color 0.2s;
}

.user-menu-item:hover {
  background-color: #a3a3a3;
}

.logout-button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  cursor: pointer;
  color: black;
  font-size: 14px;
}

.logout-button:hover {
  background-color: #a3a3a3;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserMenu/UserMenu.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;EACf,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,yBAAyB;EACzB,wCAAwC;EACxC,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".user-menu-container {\n  position: relative;\n  display: inline-block;\n}\n\n.user-icon-button {\n  background: none;\n  border: none;\n  color: white;\n  font-size: 20px;\n  cursor: pointer;\n  padding: 10px;\n}\n\n.user-menu {\n  position: absolute;\n  top: 40px;\n  right: 0;\n  background-color: #e4e4e4;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 4px;\n  overflow: hidden;\n  z-index: 1000;\n  width: 14.5rem;\n}\n\n.user-menu-item {\n  display: block;\n  padding: 10px 20px;\n  text-decoration: none;\n  color: black;\n  font-size: 14px;\n  transition: background-color 0.2s;\n}\n\n.user-menu-item:hover {\n  background-color: #a3a3a3;\n}\n\n.logout-button {\n  background: none;\n  border: none;\n  width: 100%;\n  text-align: left;\n  padding: 10px 20px;\n  cursor: pointer;\n  color: black;\n  font-size: 14px;\n}\n\n.logout-button:hover {\n  background-color: #a3a3a3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
