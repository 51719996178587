import React from "react";
import "./css/Input.css";

const ToggleCheckbox = ({ checked, onchange, label }) => {
  return (
    <label className="toggle-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={onchange}
      />
      <span className="slider"></span>
      {label && <span className="label">{label}</span>}
    </label>
  );
};

export default ToggleCheckbox;
