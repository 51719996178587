import React, { useEffect } from "react";
import Markdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot } from "@fortawesome/free-solid-svg-icons";
import {
  ChatMessages,
  MessageContainer,
  MessageText,
  TypingIndicator,
  Dot,
  MessageIcon
} from "./MessageList"; // Importando os Styled Components

const MessageList = ({ messages, isTyping, botTyping, currentBotMessage, messagesEndRef }) => {
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages, isTyping, botTyping, currentBotMessage]);

  return (
    <ChatMessages>
      {messages.map((message, index) => (
        <MessageContainer key={index} className={message.sender}>
          <MessageIcon icon={message.sender === "user" ? faUser : faRobot} />
          <MessageText className={message.sender}>
            <Markdown>{message.text}</Markdown>
          </MessageText>
        </MessageContainer>
      ))}

      {botTyping && (
        <MessageContainer className="bot">
          <MessageIcon icon={faRobot} />
          <MessageText>
            <Markdown>{currentBotMessage}</Markdown>
          </MessageText>
        </MessageContainer>
      )}

      {isTyping && !botTyping && (
        <MessageContainer className="bot">
          <MessageIcon icon={faRobot} />
            <TypingIndicator>
              <Dot />
              <Dot />
              <Dot />
            </TypingIndicator>
        </MessageContainer>
      )}

      <div ref={messagesEndRef} />
    </ChatMessages>
  );
};

export default MessageList;
