import React from "react";
import { getNewId } from "../../services/utils/idService";
import "./css/Input.css";

export default function FileUploader({
  className = "",
  multiple = false,
  onUpload = null,
  id = getNewId(),
  children: description = "Carregar arquivos",
}) {
  const handleFileUpload = (e) => {
    if (onUpload) {
      onUpload(e);
    }
  };

  return (
    <div className={`file-uploader ${className}`}>
      <label htmlFor={id} className="file-uploader__button">
        {description}
      </label>
      <input
        id={id}
        type="file"
        onChange={handleFileUpload}
        className="file-uploader__input"
        multiple={multiple}
      />
    </div>
  );
}
