import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Chatbot from "./components/Chatbot/Chatbot";
import Login from "./components/Login/Login";
import UserProfile from "./components/UserProfile/UserProfile.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import Messageboard from "./components/Messageboard/Messageboard.jsx";
const App = () => {
  const [token, setToken] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  const handleLoginSuccess = (token, client_id) => {
    setToken(token);
    setClientId(client_id);
    localStorage.setItem("token", token);
  };

  const handleLogout = () => {
    setToken(null);
    setClientId(null);
    setSessionId(null);
  };

  const handleSessionId = (session) => {
    setSessionId(session);
  };

  if (!token) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<Login onLoginSuccess={handleLoginSuccess} />}
        />
        <Route
          path="/user-profile"
          element={<UserProfile token={token} clientId={clientId} />}
        />
        <Route
          path="/"
          element={
            <Chatbot
              token={token}
              clientId={clientId}
              onLogout={handleLogout}
              sessionId={sessionId}
              onSessionId={handleSessionId}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <Dashboard
              token={token}
              clientId={clientId}
              sessionId={sessionId}
            />
          }
        />
        <Route
          path="/messageboard"
          element={
            <Messageboard
              token={token}
              clientId={clientId}
              sessionId={sessionId}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
