import React from "react";
import Card from "../Card/Card";
import { GridContainer, Grid } from "./CardGrid";

const CardGrid = ({ totalTokens, totalFilesTokens, lastMonthTokens, totalMessages, totalFilesSize, totalMessagesSize, averageTokens }) => (
        <GridContainer>
          <Grid>
            <Card title="Tokens mensagens" value={totalTokens} />
            <Card title="Tokens arquivos" value={totalFilesTokens} />
            <Card title="Tokens do último mês" value={lastMonthTokens} />
            <Card title="Total tokens" value={totalTokens + totalFilesTokens} />
            <Card title="Mensagens no período" value={totalMessages * 2} />
            <Card title="Arquivos enviados (kB)" value={totalFilesSize.toFixed(1)} />
            <Card title="Média Tokens/Mês" value={averageTokens} />
            <Card title="Mensagens enviadas (kB)" value={totalMessagesSize.toFixed(1)} />
          </Grid>
        </GridContainer>
);

export default CardGrid;
