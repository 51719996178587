import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 40vw;
  text-align: center;
  height: 40vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const DragDropArea = styled.div`
  border: 2px dashed #4f9deb;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;

  &.active {
    background-color: #e0f4ff;
  }

  p {
    font-size: 16px;
    color: #666;
  }
`;

export const UploadButton = styled.button`
  padding: 10px 20px;
  background-color: white;
  color: #4f9deb;
  border:solid 1px #4f9deb;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`;

export const FileList = styled.div`
  margin-top: 1rem;
  div {
    margin-bottom: 0.5rem;
  }
  button {
    margin-left: 1rem;
    color: red;
    cursor: pointer;
  }
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    button{
    background-color: white;
    border:none;
    color: #4f9deb;
    font-weight: bold;
    cursor: pointer;
    }
`
export const ConfirmUpload = styled.button`
    padding: 10px 20px;
    background-color: #4f9deb;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: fit-content;
`;