import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import apiTokenConsumption from "../../services/api/apiTokenConsumption";
import evaApi from "../../services/api/evaApi";
import CardGrid from "../CardGrId/CardGrid.jsx";
import ChartSection from "../ChartSection/CharSection.jsx";
import DateForm from "../DateForm/DateForm.jsx";
import RefreshControl from "../RefreshControl/RefreshControl.jsx";
import Footer from "../Footer/Footer.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Background, MainDashboard, FlexContainer, DashboardTitle,Button, CardTitle, CardContainer } from "./Dashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ clientId, sessionId, onLogout }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [messagesLength, setMessagesLength] = useState(0);
  const [grafic, setGrafic] = useState('dia');
  const [count, setCount] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(60);
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 60 * 60 * 1000;

  
  useEffect(() => {
    const resetTimer = () => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current);
      }
      inactiveTimerRef.current = setTimeout(() => {
        onLogout()
      }, inactivityLimit);
    };
    resetTimer();
    
    return () => {
      clearTimeout(inactiveTimerRef.current); 
    };
  }, []);

  useEffect(()=>{
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 15);
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = today.toISOString().split('T')[0];
    setEndDate(formattedEndDate);
    setStartDate(formattedStartDate);
    
  },[])
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiTokenConsumption.get_tokens(
          clientId,
          startDate,
          endDate
        );
        if(response.status === 401 || response.status === 403){
          onLogout()
        }  
        const result = response;
        setData(result.data);

        const responseMesssages = await evaApi.getMessages(
          clientId,
          startDate,
          endDate
        );
        if(responseMesssages.status === 401 || responseMesssages.status === 403){
          onLogout()
        }  
        const messagesLen = responseMesssages.data.messages.length;
        setMessagesLength(messagesLen);
      } catch (error) {
        console.error("Error fetching token consumption data:", error);
      }
    };
    fetchData();
  }, [clientId, startDate, endDate, count]);

  const handleRefresh =()=>{
    setCount(prevCount => prevCount + 1)
  }

  useEffect(() => {
  const intervalId = setInterval(() => {
    handleRefresh();
  }, refreshInterval * 1000);
  
  return () => clearInterval(intervalId);
}, [refreshInterval, count]); 


  if (!data) return <div className="loading-container">Carregando...</div>;

  // Process the data for the chart
  const processedData = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.day}/${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedDataFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedTotalTokens = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_tokens + item.message_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeMessages = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  function joinFirstAndLastEverySeven(arr) {
    const result = [];
    
    for (let i = 0; i < arr.length; i += 7) {
      const chunk = arr.slice(i, i + 7);  
      if (chunk.length > 1) {
        result.push(`${chunk[0]} - ${chunk[chunk.length - 1]}`);  
      } else if (chunk.length === 1) {
        result.push(chunk[0]);  
      }
    }
  
    return result;
  }  

  function sumEverySeven(arr) {
    const result = [];
  
    for (let i = 0; i < arr.length; i += 7) {
      const chunk = arr.slice(i, i + 7);  
      const sum = chunk.reduce((acc, val) => acc + val, 0);  
      result.push(sum);  
    }
  
    return result;
  }
  

  const chartData = {
    labels: grafic==='dia' ? processedData.labels : joinFirstAndLastEverySeven(processedData.labels),
    datasets: [
      {
        label: "Tokens Consumed",
        data: grafic === 'dia' ? processedTotalTokens.values : sumEverySeven(processedTotalTokens.values),
        borderColor: "rgb(79, 157, 235)",
        tension: 0.3,
      },
    ],
  };

  const totalMessagesSize = processedSizeMessages.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalTokens = processedTotalTokens.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesTokens = processedDataFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesSize = processedSizeFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const averageTokens = Math.round(totalTokens / processedData.values.length);
  const lastMonthTokens =
    processedData.values[processedData.values.length - 1] +
    processedDataFiles.values[processedDataFiles.values.length - 1]; 

  const handleGoBack = () => {
    navigate("/", { clientId, sessionId });
  };

  return (
    <>
      <Background>
        <FlexContainer>
          <DashboardTitle>Dashboard</DashboardTitle>
          <Button onClick={handleGoBack}>
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </Button>
        </FlexContainer>

        <CardContainer>
          <div>
          <CardTitle>Consumo de tokens</CardTitle>
          <RefreshControl
            onRefresh={handleRefresh}
            interval={refreshInterval}
            increaseInterval={() => setRefreshInterval(prev => prev + 10)}
            decreaseInterval={() => setRefreshInterval(prev => Math.max(10, prev - 10))}
          />
          </div>
            
          <DateForm
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={setStartDate}
            onChangeEndDate={setEndDate}
            grafic={grafic}
            setGrafic={setGrafic}
          />
        </CardContainer>

        <MainDashboard>
          <CardGrid
            totalTokens={ totalTokens}
            totalFilesTokens={totalFilesTokens}
            lastMonthTokens={lastMonthTokens}
            totalMessages={ messagesLength}
            totalFilesSize={totalFilesSize}
            totalMessagesSize={totalMessagesSize}
            averageTokens={ averageTokens}
          />
          <ChartSection grafic={grafic} data={ chartData} />
        </MainDashboard>
      </Background>
      <Footer />
    </>
  );
};

export default Dashboard;