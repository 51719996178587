import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Chatbot from "./components/Chatbot/Chatbot.jsx";
import Login from "./components/Login/Login.jsx";
import UserProfile from "./components/UserProfile/UserProfile.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import Messageboard from "./components/Messageboard/Messageboard.jsx";
import Widget from "./components/Widget/Widget.jsx";

const App = () => {
  const [token, setToken] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedClientId = localStorage.getItem("clientId");
    const storedSessionId = localStorage.getItem("sessionId");

    if (storedToken !== null) {
      setToken(storedToken);
      if (storedClientId !== null) setClientId(storedClientId);
      if (storedSessionId !== null) setSessionId(storedSessionId);
    }
  }, []);

  const handleLoginSuccess = (token, client_id) => {
    setToken(token);
    setClientId(client_id);
    localStorage.setItem("token", token);
    localStorage.setItem("clientId", client_id);
  };

  const handleLogout = () => {
    setToken(null);
    setClientId(null);
    setSessionId(null);
    localStorage.removeItem("token");
    localStorage.removeItem("clientId");
    localStorage.removeItem("sessionId");
  };

  const handleSessionId = (session) => {
    setSessionId(session);
    localStorage.setItem("sessionId", session);
  };

  return (
    <Router>
      <RoutesComponent 
        token={token}
        handleLoginSuccess={handleLoginSuccess}
        handleLogout={handleLogout}
        clientId={clientId}
        sessionId={sessionId}
        handleSessionId={handleSessionId}
      />
    </Router>
  );
};

// Mova a lógica condicional para dentro do componente RoutesComponent
const RoutesComponent = ({ token, handleLoginSuccess, handleLogout, clientId, sessionId, handleSessionId }) => {
  const location = useLocation(); // Agora está dentro do Router

  // Verifica a rota e decide o que fazer
  if (!token && location.pathname !== '/widget') {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={<Login onLoginSuccess={handleLoginSuccess} />}
      />
      <Route
        path="/user-profile"
        element={
          <UserProfile
            token={token}
            clientId={clientId}
            sessionId={sessionId}
            onLogout={handleLogout}
          />
        }
      />
      <Route
        path="/"
        element={
          <Chatbot
            token={token}
            clientId={clientId}
            onLogout={handleLogout}
            sessionId={sessionId}
            onSessionId={handleSessionId}
          />
        }
      />
      <Route
        path="/dashboard"
        element={
          <Dashboard
            token={token}
            clientId={clientId}
            sessionId={sessionId}
            onLogout={handleLogout}
          />
        }
      />
      <Route
        path="/messageboard"
        element={
          <Messageboard
            token={token}
            clientId={clientId}
            sessionId={sessionId}
            onLogout={handleLogout}
          />
        }
      />
      <Route
        path="/widget" // Rota específica para o widget
        element={
          <Widget
            clientId={clientId}
            sessionId={sessionId}
            onSessionId={handleSessionId}
          />
        }
      />
    </Routes>
  );
};

export default App;
