import React from "react";
import DateInput from "../common/DateInput";
import { SubmitButton, FormContainer } from "./DateForm";


const DateForm = ({ startDate, endDate, onChangeStartDate, onChangeEndDate, grafic, setGrafic }) => (
  <FormContainer>
    <div>
    <SubmitButton active={grafic === "dia"} onClick={() => setGrafic("dia")}>
      Dia
    </SubmitButton>
    <SubmitButton active={grafic === "semana"} onClick={() => setGrafic("semana")}>
      Semana
    </SubmitButton>
    </div>
    
    
    <div>
      <DateInput id="startDate" label="Data inicial" value={startDate} onChange={onChangeStartDate} />
      <DateInput id="endDate" label="Data final" value={endDate} onChange={onChangeEndDate} />
    </div>
    
  </FormContainer>
);

export default DateForm;
