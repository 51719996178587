// src/services/api/fileApi.js

import apiClient from "./apiClient";

const fileApi = {
  uploadFile: (file, client_id) => {
    return apiClient.uploadFile("/upload", file, client_id);
  },

  processFile: (client_id, session_id) => {
    return apiClient.processFile("/process", client_id, session_id);
  },

  getFiles: async (client_id) => {
    return await apiClient.post("/getfiles", { client_id });
  },

  deleteFile: (client_id, filename) => {
    return apiClient.delete("/delete", {
      data: { client_id },
      params: { filename: filename },
    });
  },
};

export default fileApi;
