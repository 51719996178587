import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { RefreshContainer, Button } from "./RefreshControl";

const RefreshControl = ({ onRefresh, interval, increaseInterval, decreaseInterval }) => (
  <RefreshContainer>
    <Button onClick={onRefresh}>
      <FontAwesomeIcon icon={faSync} /> Atualizar
    </Button>
    <span>Atualizar a cada {interval} segundos</span>
    <Button onClick={increaseInterval}>
      <FontAwesomeIcon icon={faPlus} />
    </Button>
    <Button onClick={decreaseInterval}>
      <FontAwesomeIcon icon={faMinus} />
    </Button>
  </RefreshContainer>
);

export default RefreshControl;
