import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./UserMenu.css";

export default function UserMenu({
    onLogout = null
}
) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = () => {
        if (onLogout) {
            onLogout();
            setMenuOpen(false);
        }
    };

    return (
        <div className="user-menu-container">
            <button className="user-icon-button" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faUser}/>
            </button>
            {menuOpen && (
                <div className="user-menu">
                    <Link to="/user-profile" className="user-menu-item" onClick={toggleMenu}>
                        Visualizar/Alterar Dados
                    </Link>
                    <button className="user-menu-item logout-button" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            )}
        </div>
    );
};

