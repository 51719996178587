// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  height: 5rem;
  text-align: center;
}

.cardText {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  color: #4b5563;
}

.cardValue {
  font-size: 1.875rem;
  font-weight: 700;
  color: #2563eb;
  margin: 1rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;EACrB;sCACoC;EACpC,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB","sourcesContent":[".card {\n  background-color: white;\n  border-radius: 0.5rem;\n  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),\n    0 2px 4px -1px rgba(0, 0, 0, 0.06);\n  padding: 1rem;\n  height: 5rem;\n  text-align: center;\n}\n\n.cardText {\n  font-size: 1.125rem;\n  font-weight: 600;\n  margin: 0;\n  color: #4b5563;\n}\n\n.cardValue {\n  font-size: 1.875rem;\n  font-weight: 700;\n  color: #2563eb;\n  margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
