import React from "react";
import { Line } from "react-chartjs-2";
import { ChartContainer, ChartTitle } from "./ChartSection";

const ChartSection = ({ grafic, data }) => (
  <ChartContainer>
    <ChartTitle>Consumo de tokens por {grafic}</ChartTitle>
    <div className="chart">
      <Line
        height={256}
        width={300}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: { beginAtZero: true },
          },
        }}
      />
    </div>
  </ChartContainer>
);

export default ChartSection;
