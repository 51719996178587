import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import evaApi from "../../services/api/evaApi";
import DateInput from "../common/DateInput";
import "./Messageboard.css";

const Messageboard = ({ clientId, sessionId }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [startDate, setStartDate] = useState("2024-09-01");
  const [endDate, setEndDate] = useState("2024-09-30");

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await evaApi.getGroupedMessages(
          clientId,
          startDate,
          endDate
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, [startDate, endDate, sessionId]);

  const handleGoBack = () => {
    navigate("/", { clientId, sessionId });
  };

  return (
    <div className="messageboard dashboard-container">
      <div className="flex-container">
        <h1 className="dashboard-title">Mensagens</h1>
        <button onClick={handleGoBack} className="button-style">
          Voltar
        </button>
      </div>
      <div className="chart-container">
        <h2 className="card-title">Consulta de mensagens por período</h2>
        <div className="filter-container">
          <DateInput
            id="startDate"
            label="Data inicial"
            value={startDate}
            onChange={(e) => setStartDate(e)}
          />
          <DateInput
            id="endDate"
            label="Data final"
            value={endDate}
            onChange={(e) => setEndDate(e)}
          />
        </div>
      </div>
      <div className="messages-container">
        {Object.keys(messages).map((data) => (
          <div key={data}>
            <h4>Data: {data}</h4>
            {Object.keys(messages[data]).map((session) => (
              <div key={session}>
                <h5>Session: {session}</h5>
                {messages[data][session].map((item, index) => (
                  <div
                    key={index}
                    style={{ marginLeft: "20px" }}
                    className="message-item"
                  >
                    <p className="message-timestamp">
                      <strong>Data:</strong> {item.data}
                    </p>
                    <p className="message-timestamp">
                      <strong>Rating:</strong> {item.rating || "N/A"}
                    </p>
                    <p className="message-timestamp">
                      <strong>Pergunta:</strong> {item.pergunta}
                    </p>
                    <p className="message-timestamp">
                      <strong>Resposta:</strong> {item.resposta}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Messageboard;
