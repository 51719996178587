import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import apiTokenConsumption from "../../services/api/apiTokenConsumption";
import Card from "../Card/Card";
import DateInput from "../common/DateInput";
import evaApi from "../../services/api/evaApi";
import "./Dashboard.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ clientId, sessionId }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState("2024-01-01");
  const [endDate, setEndDate] = useState("2024-12-31");
  const [messagesLength, setMessagesLength] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiTokenConsumption.get_tokens(
          clientId,
          startDate,
          endDate
        );
        const result = await response;
        setData(result.data);

        const responseMesssages = await evaApi.getMessages(
          clientId,
          startDate,
          endDate
        );
        const messagesLen = responseMesssages.data.messages.length;
        setMessagesLength(messagesLen);
      } catch (error) {
        console.error("Error fetching token consumption data:", error);
      }
    };
    fetchData();
  }, [clientId, startDate, endDate]);

  if (!data) return <div className="loading-container">Carregando...</div>;

  // Process the data for the chart
  const processedData = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.total_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedDataFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedTotalTokens = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_tokens + item.total_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeMessages = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  const chartData = {
    labels: processedData.labels,
    datasets: [
      {
        label: "Tokens Consumed",
        data: processedTotalTokens.values,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const totalMessagesSize = processedSizeMessages.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalTokens = processedTotalTokens.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesTokens = processedDataFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesSize = processedSizeFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const averageTokens = Math.round(totalTokens / processedData.values.length);
  const lastMonthTokens =
    processedData.values[processedData.values.length - 1] +
    processedDataFiles.values[processedDataFiles.values.length - 1];

  const handleFilter = (e) => {
    e.preventDefault();
    // The useEffect hook will trigger the API call when startDate or endDate change
  };

  const handleGoBack = () => {
    navigate("/", { clientId, sessionId });
  };

  return (
    <div className="background-dashboard">
      <div className="flex-container">
        <h1 className="dashboard-title">Dashboard</h1>
        <button onClick={handleGoBack} className="button-style">
          Voltar
        </button>
      </div>

      <div className="cardGrid">
        <Card title="Tokens mensagens" value={totalTokens} />
        <Card title="Tokens arquivos" value={totalFilesTokens} />
        <Card title="Tokens do último mês" value={lastMonthTokens} />
        <Card title="Total tokens" value={totalFilesTokens + totalTokens} />
      </div>

      <div className="cardGrid">
        <Card title="Mensagens no período" value={messagesLength * 2} />
        <Card
          title="Arquivos enviados (kB)"
          value={totalFilesSize.toFixed(1)}
        />
        <Card title="Média Tokens/Mês" value={averageTokens} />
        <Card
          title="Mensagens enviadas (kB)"
          value={totalMessagesSize.toFixed(1)}
        />
      </div>
      <div className="card-container">
        <h2 className="card-title">Consumo de tokens</h2>
        <form onSubmit={handleFilter} className="form-container">
          <DateInput
            id="startDate"
            label="Data inicial"
            value={startDate}
            onChange={setStartDate}
          />
          <DateInput
            id="endDate"
            label="Data final"
            value={endDate}
            onChange={setEndDate}
          />
          <button type="submit" className="submit-button">
            Filtrar
          </button>
        </form>
      </div>

      <div className="chart-container">
        <h2 className="card-title">Consumo de tokens por período</h2>
        <div>
          <Line
            height={256}
            width={320}
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
