// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #a3a3a3;
}

.login-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 80px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.login-input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.login-button {
  padding: 10px;
  background-color: #333333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB","sourcesContent":["body {\n  background-color: #a3a3a3;\n}\n\n.login-container {\n  display: flex;\n  flex-direction: column;\n  width: 300px;\n  margin: 80px auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 10px;\n  background-color: #f9f9f9;\n}\n\n.login-input {\n  margin-bottom: 10px;\n  padding: 10px;\n  font-size: 16px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n}\n\n.login-button {\n  padding: 10px;\n  background-color: #333333;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.error-message {\n  color: red;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
