import styled from "styled-components";
import Button from "../common/Button";

export const SidebarContainer = styled.aside`
  width: 280px;
  background-color: #d9e9fb80; /* Cor original para telas maiores */
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed; /* Mantém fixo */
  right: ${(props) => (props.isSidebarOpen ? "0" : "-100%")}; /* Ajuste de posição para mobile */
  top: 0;
  height: 100vh;
  transition: right 0.3s ease-in-out; /* Transição suave */
  z-index: 1000;

  @media (min-width: 768px) {
    position: static; /* Para dispositivos maiores, não queremos que seja fixo */
    right: 0; /* Certifica que está no lugar correto */
    width: 280px; /* Redefine a largura */
    height: auto; /* Ajusta a altura */
    transition: none; /* Remove a transição */
    transform: none; /* Remove qualquer transformação que afaste o sidebar */
    display: flex; /* Garante que seja visível em telas maiores */
  }

  @media (min-width: 1500px) {
    width: 350px;
  }
`;

export const MobileMenuButton = styled.button`
  background-color: transparent;
  border: none;
  position: fixed;
  top: 20px;
  right: 20px; /* Alinhar o botão à direita */
  z-index: 1100;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none; /* Oculta o botão em telas maiores */
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
  z-index: 900;
  display: ${(props) => (props.isSidebarOpen ? "block" : "none")}; /* Exibir apenas em mobile quando o menu estiver aberto */
  
  @media (min-width: 768px) {
    display: none; /* Não exibe em dispositivos maiores */
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 5rem;

  @media (min-width: 1500px) {
    margin-top: 10rem;
  }
`;

export const SidebarButton = styled(Button)`
  padding: 10px;
  text-align: left;
  background-color: #d9e9fb !important;
  color: #616161 !important;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  height: 40px;

  &:hover {
    background-color: transparent !important;
  }

  svg {
    margin-right: 10px;
  }

  @media (min-width: 1500px) {
    padding: 20px;
    font-size: 16px;
    height: 60px;
    border-radius: 28px;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 0.5rem;

  img:first-child {
    width: 30%;
    @media (min-width: 1500px) {
      width: 40%;
    }
  }

  img:last-child {
    width: 40%;
    @media (min-width: 1500px) {
      width: 60%;
    }
  }
`;

export const ToggleContainer = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
`;
