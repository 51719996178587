import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UserMenu from "../UserMenu/UserMenu.jsx";
import EvaLogo from "../../images/EVA.png";
import { HeaderContainer, BackButton, FooterLogo,Logo, TitleContainer, Title } from "./Header";


const Header = ({ onLogout = null, showUserMenu = true, showBackButton = false, handleGoBack }) => {
  const handleLogout = () => {
    if (onLogout) onLogout();
  };

  return (
    <HeaderContainer className={`${showUserMenu ? "no-width" : ""} ${showBackButton ? "backButton" : ""}`}>
      <div>
        <TitleContainer>
          <Logo alt="eva logo" src={EvaLogo} />
          <Title>Assistente</Title>
        </TitleContainer>

        <FooterLogo className={`${showUserMenu ? "" : "noshow"}`}>
          <i>{process.env.REACT_APP_AMBIENTE}</i>
        </FooterLogo>
      </div>

      {showUserMenu && <UserMenu onLogout={handleLogout} />}
      {showBackButton && (
        <BackButton onClick={handleGoBack}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar
        </BackButton>
      )}
    </HeaderContainer>
  );
};

export default Header;
