import styled from "styled-components";

export const ChartContainer = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  border: 1px solid #4f9deb;
  width: 50%;
  @media (max-width: 770px) {
    width: calc(90% - 2rem);
    margin-top: 1rem;
  }
`;

export const ChartTitle = styled.h2`
  color: #4f9deb;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
`;