import apiClient from "./apiClient";

const evaApi = {
  askai: (body = {}) => {
    return apiClient.post(`/askai`, body);
  }, // Change semicolon to comma here

  getSession: (sessionId) => {
    return apiClient.get(`/getsession?session=${sessionId}`);
  },

  getMessages: (clientId, startDate, endDate) => {
    return apiClient.get(
      `/getmessages?client_id=${clientId}&start_date=${startDate}&end_date=${endDate}`
    );
  },

  getGroupedMessages: (clientId, startDate, endDate) => {
    return apiClient.get(
      `/getgroupedmessages?client_id=${clientId}&start_date=${startDate}&end_date=${endDate}`
    );
  },
};

export default evaApi;
