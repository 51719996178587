import styled from "styled-components";

export const Background = styled.div`
  padding: 3rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
    align-items: flex-start;
    width: calc(100% - 2rem);
   
  }
`;

export const DashboardTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #4f9deb;
  

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const CardContainer = styled.div`
  background-color: white;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #4f9deb;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    gap:1rem;

    & > :first-child {
    display: flex;
    width: calc(100% - 2rem);
    justify-content: space-between;
    align-items: flex-end;
  }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const CardTitle = styled.h3`
  color: #4f9deb;
  font-size: 18px;
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-right:0.5rem;
  }
`;

export const Button = styled.button`
  font-size: 1rem;
  background-color: white;
  border: none;
  color: #4f9deb;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #2b7fbf;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const MainDashboard = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
