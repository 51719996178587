import React, { useState, useEffect, useRef } from "react";
import evaApi from "../../services/api/evaApi";
import fileApi from "../../services/api/fileApi";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Input from "../common/Input";
import Markdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot } from "@fortawesome/free-solid-svg-icons";
import "./Chatbot.css";

const Chatbot = ({ token, clientId, onLogout, sessionId, onSessionId }) => {
  const [session, setSession] = useState("");
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [habilitaImagens, setHabilitaImagens] = useState(false);
  const [habilitaEmail, setHabilitaEmail] = useState(false);
  const [habilitaZendesk, setHabilitaZendesk] = useState(false);
  const [habilitaContexto, setHabilitaContexto] = useState(true);
  const shouldLog = useRef(true);
  const [uploadNotification, setUploadNotification] = useState(null);

  const handleHabilitaImagens = (value) => {
    setHabilitaImagens(value);
  };

  const handleHabilitaEmail = (value) => {
    setHabilitaEmail(value);
  };

  const handleHabilitaZendesk = (value) => {
    setHabilitaZendesk(value);
  };

  const handleHabilitaContexto = (value) => {
    setHabilitaContexto(value);
  };

  const handleNotification = (value) => {
    setUploadNotification(value);
    setTimeout(() => setUploadNotification(null), 3000);
  };

  useEffect(() => {
    if (session === "") {
      if (sessionId) {
        const fetchSession = async () => {
          try {
            const response = await evaApi.getSession(sessionId);
            const sessionData = await response.data.messages.sort((a, b) =>
              a.dat > b.data ? 1 : b.data > a.data ? -1 : 0
            );
            setSession(sessionId);
            setMessages([]);
            for (let i = 0; i < sessionData.length; i++) {
              const messageList = [];
              if (i === 0) {
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                });
              } else {
                messageList.push({
                  sender: "user",
                  text: sessionData[i]["pergunta"],
                });
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                });
              }
              setMessages((prevMessages) => [...prevMessages, ...messageList]);
            }
          } catch (error) {
            console.error("Error fetching session:", error);
            // Handle error (e.g., show error message to user)
          }
        };
        fetchSession();
      } else if (shouldLog.current) {
        shouldLog.current = false;

        const grettings = async () => {
          setMessages([{ sender: "bot", text: ". . ." }]);

          const body = {
            query:
              "Se apresente de maneira informal para o usuário falando sobre é um assistente virtual especializado da empresa e irá ajudá-lo",
            memory:
              '{"role": "system", "content": "Você é um representante comercial querendo vender um produto ou uma franquia. Lembre todas as perguntas que o humano fizer"}',
            searchdocs: false,
            temperature: 0.9,
            template: "template_saudacao",
            client_id: clientId,
            email: false,
            zendesk: false,
            sessionid: "",
          };

          const response = await evaApi.askai(body, token);
          const data = await response.data;
          const botMessage = { sender: "bot", text: data.message };
          setMessages([botMessage]);
          setSession(data.sessionid);
          onSessionId(data.sessionid);
        };

        grettings();
      }
    }
  }, [session, sessionId, clientId, token]);

  const handleNewChat = () => {
    shouldLog.current = true;
    setSession("");
    onSessionId("");
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "user", text: input };
    setMessages([...messages, userMessage]);
    setInput("");

    const body = {
      query: input,
      memory: "{}",
      searchdocs: true,
      temperature: 0.1,
      template: habilitaContexto ? "template_contexto" : "template_internet",
      client_id: clientId,
      email: habilitaEmail,
      zendesk: habilitaZendesk,
      sessionid: session,
    };

    const response = await evaApi.askai(body);
    const data = await response.data;
    const botMessage = { sender: "bot", text: data.message };
    setMessages([...messages, userMessage, botMessage]);
  };

  const handleFileUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        await fileApi.uploadFile(file, clientId);
      }
      let ret = await fileApi.processFile(clientId, session);
      if (ret.status === 200) {
        handleNotification("Arquivo processado com sucesso!");
      }
    }
  };

  return (
    <div className="main-container">
      <Header onLogout={onLogout} showUserMenu={true} />
      {uploadNotification && (
        <div className="upload-notification">{uploadNotification}</div>
      )}
      <div className="content">
        <Sidebar
          onFileUpload={handleFileUpload}
          clientId={clientId}
          token={token}
          onNewChat={handleNewChat}
          onHabilitaImagens={handleHabilitaImagens}
          onHabilitaEmail={handleHabilitaEmail}
          onHabilitaZendesk={handleHabilitaZendesk}
          onHabilitaContexto={handleHabilitaContexto}
          sessionId={session}
          deleteNotification={handleNotification}
        />
        <div className="chat-container">
          {/*<div className="chatBox">*/}
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                <FontAwesomeIcon
                  icon={message.sender === "user" ? faUser : faRobot}
                  className="message-icon"
                />
                <div className="message-text">
                  <Markdown
                    key={index}
                    className={
                      message.sender === "user" ? "userMessage" : "botMessage"
                    }
                  >
                    {message.text}
                  </Markdown>
                </div>
              </div>
            ))}
          </div>
          <div className="inputBox">
            <Input
              type="text"
              value={input}
              onChange={handleInputChange}
              placeholder="Digite sua mensagem..."
            />
            <button onClick={handleSendMessage} className="sendButton">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
