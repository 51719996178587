import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./FileListModal.css";

export default function FileListModal({
  isOpen,
  onClose,
  files,
  onDeleteFile,
}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Arquivos Carregados</h2>
        <div className="file-list-header">
          <span>Nome do arquivo</span>
          <span>Data de upload</span>
        </div>
        <ul className="file-list">
          {files.length > 0 ? (
            files.map((fileObj, index) => (
              <li key={index} className="file-item">
                <span className="file-name">{fileObj.file}</span>
                <span className="file-date">{fileObj.date}</span>
                <button
                  className="delete-button"
                  onClick={() => onDeleteFile(fileObj.file)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </li>
            ))
          ) : (
            <li className="file-item">Nenhum arquivo carregado</li>
          )}
        </ul>
        <button className="button-style" onClick={onClose}>
          Fechar
        </button>
      </div>
    </div>
  );
}
