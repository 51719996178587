import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  height: 6rem;
  width: 8rem;
  text-align: center;
  border: 1px solid #4f9deb;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: 1500px) {
    padding: 0.8rem;
    height: 8rem;
    width: 10rem;
    gap: 0.5rem;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    padding: 0.8rem;
    height: 5.2rem;
    width: 6rem;
    gap: 0.5rem;
  }

  @media (max-width: 600px) {
    padding: 0.5rem;
    height: 4.5rem;
    width: 5.5rem;
    gap: 0.3rem;
  }
`;

export const CardText = styled.h3`
  font-size: 15px;
  font-weight: 600;
  color: #4b5563;

  @media (min-width: 1500px) {
    font-size: 1.2rem;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

export const CardValue = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #4f9deb;
  margin: 0 !important;

  @media (min-width: 1500px) {
    font-size: 1.5rem;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;
