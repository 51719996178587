// src/Login.js
import React, { useState } from "react";
import "./Login.css";
import userApi from "../../services/api/userApi";
import Header from "../Header/Header";

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await userApi.login(username, password);
      if (response.status !== 200) {
        throw new Error("Falha no login");
      }

      const data = await response.data;
      const token = data.message;
      onLoginSuccess(token, username);
    } catch (error) {
      setError("Falha no login. Verifique suas credenciais.");
    }
  };

  return (
    <>
      <Header showUserMenu={false} />
      <div className="login-container">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <input
          type="text"
          value={username}
          onChange={handleUsernameChange}
          placeholder="Usuário"
          className="login-input"
        />
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Senha"
          className="login-input"
        />
        <button onClick={handleLogin} className="login-button">
          Entrar
        </button>
      </div>
    </>
  );
};

export default Login;
