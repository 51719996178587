import styled from "styled-components";

export const RefreshContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

export const Button = styled.button`
  background-color: white;
  border: none;
  color: #4f9deb;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #2b7fbf;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem; 
    padding: 0.25rem 0.5rem;
  }
`;
