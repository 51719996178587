import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userApi from "../../services/api/userApi";
import Header from "../Header/Header";
import './UserProfile.css'

const UserProfile = ({token, clientId}) => {
  const [userData, setUserData] = useState({
    id: "",
    client_id: "",
    nome: "",
    fantasia: "",
    api_key: "",
    active: false,
    template_saudacao: "",
    template_contexto: "",
    template_internet: ""
  });
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await userApi.getCurrentUser(clientId);

        if (response.status !== 200) {
          throw new Error("Erro ao carregar dados do usuário");
        }

        const data = await response.data;
        setUserData({
          id: data.user.id,
          client_id: data.user.id,
          nome: data.user.nome,
          fantasia: data.user.fantasia,
          api_key: data.user.api_key,
          active: data.user.active,
          template_saudacao: data.user.template_saudacao,
          template_contexto: data.user.template_contexto,
          template_internet: data.user.template_internet
        });
      } catch (error) {
        console.error("Erro ao carregar dados do usuário:", error);
      }
    };

    fetchUserData();
  }, [token, clientId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    // Optionally reload the original user data from API or reset to previous state
  };

  const handleSave = async () => {
    try {
      const response = await userApi.updateProfile(userData);

      if (response.status !== 200) {
        throw new Error("Erro ao salvar dados do usuário");
      }

      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao salvar dados do usuário:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (!userData) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      <Header showUserMenu={true} />
      <div className="user-profile-container">
        <h2>Perfil do Usuário</h2>
        {userData && (
            <form className="user-profile-form">
              <div className="form-group">
                <label>Client ID:</label>
                <input
                    type="text"
                    name="id"
                    value={userData.id}
                    onChange={handleChange}
                    disabled={true}
                />
              </div>
              <div className="form-group">
                <label>Nome:</label>
                <input
                    type="text"
                    name="name"
                    value={userData.nome}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
              </div>
              <div className="form-group">
                <label>Fantasia:</label>
                <input
                    type="text"
                    name="fantasia"
                    value={userData.fantasia}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
              </div>
              <div className="form-group">
                <label>API Key:</label>
                <input
                    type="text"
                    name="api_key"
                    value={userData.api_key}
                    onChange={handleChange}
                    disabled={true}
                />
              </div>
              <div className="form-group">
                <label>
                  Ativo:
                  <input
                      type="checkbox"
                      name="active"
                      checked={userData.active}
                      onChange={handleChange}
                      disabled={true}
                  />
                </label>
              </div>
              <div className="form-group">
                <label>Template de Saudação:</label>
                <textarea
                    name="template_saudacao"
                    value={userData.template_saudacao}
                    onChange={handleChange}
                    disabled={!isEditing}
                    rows={4}
                    style={{ width: '100%', resize: 'vertical' }}
                />
              </div>
              <div className="form-group">
                <label>Template de Contexto:</label>
                <textarea
                    name="template_contexto"
                    value={userData.template_contexto}
                    onChange={handleChange}
                    disabled={!isEditing}
                    rows={4}
                    style={{ width: '100%', resize: 'vertical' }}
                />
              </div>
              <div className="form-group">
                <label>Template de Internet:</label>
                <textarea
                    name="template_internet"
                    value={userData.template_internet}
                    onChange={handleChange}
                    disabled={!isEditing}
                    rows={4}
                    style={{ width: '100%', resize: 'vertical' }}
                />
              </div>
              <div className="buttons-container">
                {isEditing ? (
                    <>
                      <button type="button" onClick={handleSave}>
                      Salvar
                      </button>
                      <button type="button" onClick={handleCancel}>
                        Cancelar
                      </button>
                    </>
                ) : (
                    <button type="button" onClick={handleEdit}>
                      Alterar
                    </button>
                )}
                <button type="button" onClick={() => navigate(-1, { state: { token, clientId } })}>
                        Voltar
                      </button>
              </div>
            </form>)}
      </div>
    </>
  );
};

export default UserProfile;
