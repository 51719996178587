// src/services/api/userApi.js

import apiClient from './apiClient';

const userApi = {
  login: (client_id, password) => {
    return apiClient.post('/login', { client_id, password });
  },

  // register: (userData) => {
  //   return apiClient.post('/auth/register', userData);
  // },

  getCurrentUser: (client_id) => {
    return apiClient.post(`/user/profile/${client_id}`, {client_id});
  },
  
  updateProfile: (userData) => {
    return apiClient.put('/user', userData);
  },

  // Add more user-related API calls as needed
};

export default userApi;