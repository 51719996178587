import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import evaApi from "../../services/api/evaApi";
import DateInput from "../common/DateInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { MessageBoardContainer,
  FlexContainer,
  Button,
  FilterContainer,
  DateFilterContainer,
  MessageItem,
  MessageTimestamp,
  MessagesContainer

 } from "./Messageboard";
const Messageboard = ({ clientId, sessionId, onLogout }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 60 * 60 * 1000;

  useEffect(() => {
    const resetTimer = () => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current);
      }
      inactiveTimerRef.current = setTimeout(() => {
        onLogout();
      }, inactivityLimit);
    };
    resetTimer();

    return () => {
      clearTimeout(inactiveTimerRef.current);
    };
  }, []);

  useEffect(() => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - 15);
    const formattedPastDate = pastDate.toISOString().split('T')[0];
    const formattedDate = today.toISOString().split('T')[0];
    setEndDate(formattedDate);
    setStartDate(formattedPastDate);
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await evaApi.getGroupedMessages(clientId, startDate, endDate);
        if(response.status === 401 || response.status === 403){
          onLogout()
        } 
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, [startDate, endDate, sessionId, onLogout, clientId]);

  const handleGoBack = () => {
    navigate("/", { clientId, sessionId });
  };

  return (
    <MessageBoardContainer>
      <FlexContainer>
        <h2>Mensagens</h2>
        <Button onClick={handleGoBack}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar
        </Button>
      </FlexContainer>
      <FilterContainer>
        <h3>Consulta de mensagens por período</h3>
        <DateFilterContainer>
          <DateInput
            id="startDate"
            label="Data inicial"
            value={startDate}
            onChange={(e) => setStartDate(e)}
          />
          <DateInput
            id="endDate"
            label="Data final"
            value={endDate}
            onChange={(e) => setEndDate(e)}
          />
        </DateFilterContainer>
      </FilterContainer>
      <MessagesContainer>
        {Object.keys(messages).map((data) => (
          <div key={data}>
            <h4>Data: {data}</h4>
            {Object.keys(messages[data]).map((session) => (
              <div key={session}>
                <h5>Session: {session}</h5>
                {messages[data][session].map((item, index) => (
                  <MessageItem key={index}>
                    <MessageTimestamp>
                      <strong>Data:</strong> {item.data}
                    </MessageTimestamp>
                    <MessageTimestamp>
                      <strong>Rating:</strong> {item.rating || "N/A"}
                    </MessageTimestamp>
                    <MessageTimestamp>
                      <strong>Pergunta:</strong> {item.pergunta}
                    </MessageTimestamp>
                    <MessageTimestamp>
                      <strong>Resposta:</strong> {item.resposta}
                    </MessageTimestamp>
                  </MessageItem>
                ))}
              </div>
            ))}
          </div>
        ))}
      </MessagesContainer>
    </MessageBoardContainer>
  );
};

export default Messageboard;
