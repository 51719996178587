import "./Sidebar.css";
import "../common/css/Input.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ToggleCheckbox from "../common/ToggleCheckbox";
import Button from "../common/Button";
import FileUploader from "../common/FileUploader";
import fileApi from "../../services/api/fileApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileListModal from "../FileListModal/FileListModal";
import {
  faPlus,
  faChartBar,
  faEnvelope,
  faUpload,
  faList,
} from "@fortawesome/free-solid-svg-icons";

export default function Sidebar({
  clientId,
  token,
  sessionId,
  onFileUpload = null,
  onNewChat = null,
  onHabilitaImagens = null,
  onHabilitaEmail = null,
  onHabilitaZendesk = null,
  onHabilitaContexto = null,
  deleteNotification = null,
}) {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [habilitaImagens, setHabilitaImagens] = useState(false);
  const [habilitaEmail, setHabilitaEmail] = useState(false);
  const [habilitaZendesk, setHabilitaZendesk] = useState(false);
  const [habilitaContexto, setHabilitaContexto] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenDashboard = () => {
    navigate("/dashboard", { clientId, sessionId });
  };

  const handleHabilitaImagens = (value) => {
    if (onHabilitaImagens) {
      onHabilitaImagens(value);
    }
    setHabilitaImagens(value);
  };

  const handleHabilitaEmail = (value) => {
    if (onHabilitaEmail) {
      onHabilitaEmail(value);
    }
    setHabilitaEmail(value);
  };

  const handleHabilitaZendesk = (value) => {
    if (onHabilitaZendesk) {
      onHabilitaZendesk(value);
    }
    setHabilitaZendesk(value);
  };

  const handleHabilitaContexto = (value) => {
    if (onHabilitaContexto) {
      onHabilitaContexto(value);
    }
    setHabilitaContexto(value);
  };

  const handleDeleteNotification = (value) => {
    if (deleteNotification) {
      deleteNotification(value);
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await fileApi.getFiles(clientId);

      if (response.status !== 200) {
        throw new Error("Erro ao carregar arquivos");
      }

      const data = await response.data;
      const arrFiles = [];
      Object.entries(data).forEach(([file, date]) => {
        arrFiles.push({ file, date });
      });
      setFiles(arrFiles);
    } catch (error) {
      console.error("Erro ao carregar arquivos:", error);
    }
  };

  const handleFileUpload = async (e) => {
    if (onFileUpload) {
      onFileUpload(e);
    }
  };

  const handleNewChat = async () => {
    if (onNewChat) {
      onNewChat();
    }
  };

  const handleDeleteFile = async (fileId) => {
    const confirmDelete = window.confirm(
      "Você tem certeza que deseja deletar este arquivo?"
    );
    if (!confirmDelete) return;

    try {
      await fileApi.deleteFile(clientId, fileId);
      // Atualiza o estado local removendo o arquivo deletado
      setFiles(files.filter((file) => file !== fileId));
      handleDeleteNotification("Arquivo excluído com sucesso!");
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
      alert("Ocorreu um erro ao deletar o arquivo.");
    }
  };

  const handleOpenMessageboard = () => {
    navigate("/messageboard", { clientId, sessionId });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    fetchFiles();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <aside className="sidebar">
      <div className="toggle-container">
        <ToggleCheckbox
          checked={habilitaImagens}
          onchange={() => handleHabilitaImagens(!habilitaImagens)}
          label="Habilitar leitura de imagens"
        />
      </div>
      <div className="toggle-container">
        <ToggleCheckbox
          checked={habilitaEmail}
          onchange={() => handleHabilitaEmail(!habilitaEmail)}
          label="Habilitar envio e-mail"
        />
      </div>
      <div className="toggle-container">
        <ToggleCheckbox
          checked={habilitaZendesk}
          onchange={() => handleHabilitaZendesk(!habilitaZendesk)}
          label="Habilitar Zendesk"
        />
      </div>
      <div className="toggle-container">
        <ToggleCheckbox
          checked={habilitaContexto}
          onchange={() => handleHabilitaContexto(!habilitaContexto)}
          label={habilitaContexto ? "Somente Contexto" : "Contexto + Internet"}
        />
      </div>
      <div className="buttons-container">
        <Button className="sidebar-button" onButtonClick={handleNewChat}>
          <FontAwesomeIcon icon={faPlus} /> Novo chat
        </Button>
        <Button className="sidebar-button" onButtonClick={handleOpenDashboard}>
          <FontAwesomeIcon icon={faChartBar} /> Abrir Dashboard
        </Button>
        <Button
          className="sidebar-button"
          onButtonClick={handleOpenMessageboard}
        >
          <FontAwesomeIcon icon={faEnvelope} /> Mensagens
        </Button>
        <FileUploader
          className="sidebar-button"
          multiple={true}
          onUpload={handleFileUpload}
        >
          <FontAwesomeIcon icon={faUpload} /> Carregar arquivos
        </FileUploader>
        <Button className="sidebar-button" onButtonClick={handleOpenModal}>
          <FontAwesomeIcon icon={faList} /> Mostrar Arquivos
        </Button>
      </div>

      <FileListModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        files={files}
        onDeleteFile={handleDeleteFile}
      />
    </aside>
  );
}
