import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import userApi from "../../services/api/userApi";
import Header from "../Header/Header.jsx";
import { FormGroup, ButtonsContainer, UserProfileContainer, UserProfileForm, UserProfileInfo } from "./UserProfile.js";

const UserProfile = ({ token, clientId, sessionId, onLogout }) => {
  const [userData, setUserData] = useState({
    id: "",
    client_id: "",
    nome: "",
    fantasia: "",
    api_key: "",
    active: false,
    template_saudacao: "",
    template_contexto: "",
    template_internet: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 10 * 60 * 1000;

  useEffect(() => {
    const resetTimer = () => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current);
      }
      inactiveTimerRef.current = setTimeout(() => {
        onLogout();
      }, inactivityLimit);
    };
    resetTimer();

    return () => {
      clearTimeout(inactiveTimerRef.current);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await userApi.getCurrentUser(clientId);
        if(response.status === 401 || response.status === 403){
          onLogout()
        } else if (response.status !== 200) {
          throw new Error("Erro ao carregar dados do usuário");      
        }

        const data = response.data;
        setUserData({
          id: data.user.id,
          client_id: data.user.id,
          nome: data.user.nome,
          fantasia: data.user.fantasia,
          api_key: data.user.api_key,
          active: data.user.active,
          template_saudacao: data.user.template_saudacao,
          template_contexto: data.user.template_contexto,
          template_internet: data.user.template_internet,
        });
      } catch (error) {
        console.error("Erro ao carregar dados do usuário:", error);
      }
    };

    fetchUserData();
  }, [token, clientId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const response = await userApi.updateProfile(userData);

      if (response.status !== 200) {
        throw new Error("Erro ao salvar dados do usuário");
      }

      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao salvar dados do usuário:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleGoBack = () => {
    navigate("/", { clientId, sessionId });
  };

  return (
    <>
      <Header
        showUserMenu={false}
        showBackButton={true}
        token={token}
        clientId={clientId}
        handleGoBack={handleGoBack}
      />
      <UserProfileContainer>
        <h2>Perfil do Usuário</h2>
        <UserProfileForm>
          <UserProfileInfo>
            <FormGroup>
              <label>Client ID:</label>
              <input
                type="text"
                name="id"
                value={userData.id}
                onChange={handleChange}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <label>API Key:</label>
              <input
                type="text"
                name="api_key"
                value={userData.api_key}
                onChange={handleChange}
                disabled
              />
            </FormGroup>
          </UserProfileInfo>
          <UserProfileInfo>
            <FormGroup>
              <label>Nome:</label>
              <input
                type="text"
                name="nome"
                value={userData.nome}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </FormGroup>
            <FormGroup>
              <label>Fantasia:</label>
              <input
                type="text"
                name="fantasia"
                value={userData.fantasia}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </FormGroup>
          </UserProfileInfo>
          <FormGroup>
            <label>
              Ativo:
              <input
                type="checkbox"
                name="active"
                checked={userData.active}
                onChange={handleChange}
                disabled
              />
            </label>
          </FormGroup>
          <FormGroup>
            <label>Template de Saudação:</label>
            <textarea
              name="template_saudacao"
              value={userData.template_saudacao}
              onChange={handleChange}
              disabled={!isEditing}
              rows={4}
            />
          </FormGroup>
          <FormGroup>
            <label>Template de Contexto:</label>
            <textarea
              name="template_contexto"
              value={userData.template_contexto}
              onChange={handleChange}
              disabled={!isEditing}
              rows={4}
            />
          </FormGroup>
          <FormGroup>
            <label>Template de Internet:</label>
            <textarea
              name="template_internet"
              value={userData.template_internet}
              onChange={handleChange}
              disabled={!isEditing}
              rows={4}
            />
          </FormGroup>
          <ButtonsContainer>
            {isEditing ? (
              <>
                <button type="button" onClick={handleSave}>
                  Salvar
                </button>
                <button type="button" onClick={handleCancel}>
                  Cancelar
                </button>
              </>
            ) : (
              <button type="button" onClick={handleEdit}>
                Alterar
              </button>
            )}
          </ButtonsContainer>
        </UserProfileForm>
      </UserProfileContainer>
    </>
  );
};

export default UserProfile;
