import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  width: calc(100% - 8rem);
  margin: 5rem 3rem 1rem 3rem;

  @media (max-width: 1200px) {
    margin: 4rem 2rem 1rem 2rem;
  }

  @media (max-width: 900px) {
    width: calc(100% - 4rem);
    margin: 3rem 1rem;
  }
  @media (max-width: 770px) {
    width: calc(100% - 2rem);
    margin: 3rem 0.5rem 1rem 0.5rem;
    padding: 0.5rem;
  }


  @media (max-width: 600px) {
    width: calc(100% - 2rem);
    margin: 2.5rem 0.5rem;
    padding: 0.5rem;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  align-self: ${({ className }) =>
    className === "user" ? "flex-end" : "flex-start"};

  &.user {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  @media (max-width: 600px) {
    padding: 8px;
  }
`;

export const MessageText = styled.div`
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  border: 1px solid #4f9deb;
  margin: 5px 10px;
  border-radius: 2rem;

  p {
    font-size: 15px;
  }

  @media (max-width: 900px) {
    max-width: 70%;
    font-size: 14px;
  }

  @media (max-width: 600px) {
    max-width: 65%;
    padding: 8px;
    font-size: 13px;
  }
`;

export const MessageIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: gray;

  @media (max-width: 600px) {
    margin-right: 5px;
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const TypingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 600px) {
    margin-top: 0.5rem;
  }
`;

export const Dot = styled.div`
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #4f9deb;
  border-radius: 50%;
  animation: ${bounce} 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }

  &:nth-child(3) {
    animation-delay: 0s;
  }

  @media (max-width: 600px) {
    height: 8px;
    width: 8px;
  }
`;
