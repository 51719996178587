import "./css/Input.css"
import React from "react";

export default function Input ({type, placeholder, value, onChange}) {
    return (
        <input
            type={type}
            value={value}
            onChange={onChange}
            className="input"
            placeholder={placeholder}
        />
    );
}
