import React from "react";
import "./Header.css";
import UserMenu from "../UserMenu/UserMenu";

export default function Header({onLogout = null, showUserMenu = true}) {
    const handleLogout = () => {
        if(onLogout) onLogout();
    }

    return (
        <header className="header">
            <h1 className="title">E.V.A Assistente</h1>
            {showUserMenu && <UserMenu onLogout={handleLogout} />}
        </header>
    );
};
