import apiClient from "./apiClient";

const apiTokenConsumption = {
  get_tokens: (client_id, initial_date, final_date) => {
    return apiClient.get(
      `/consumption/${client_id}/${initial_date}/${final_date}`
    );
  },
};
export default apiTokenConsumption;
