import "./Card.css";

const Card = ({ title, value }) => (
  <div className="card">
    <h3 className="cardText">{title}</h3>
    <p className="cardValue">{value}</p>
  </div>
);

export default Card;
