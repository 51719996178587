import "./css/Input.css"

export default function Button({
  children: description = 'Descrição do botão',
  onButtonClick = null,
  className = '',
  type = 'button',
  disabled = false,
}) {
  function handleButtonClick() {
    if (onButtonClick) {
      onButtonClick();
    }
  }

  return (
    <button
      className={className}
      onClick={handleButtonClick}
      type={type}
      disabled={disabled}
    >
      {description}
    </button>
  );
}