// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-link {
  background: none;
  border: none;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.logout-link:hover {
  color: #d1e7ff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #1b1b1b;
  color: #fff;
}

.title{
  padding-left: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,0BAA0B;EAC1B,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".logout-link {\n  background: none;\n  border: none;\n  color: #fff;\n  text-decoration: underline;\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.logout-link:hover {\n  color: #d1e7ff;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n  background-color: #1b1b1b;\n  color: #fff;\n}\n\n.title{\n  padding-left: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
