import styled from "styled-components";

export const MessageBoardContainer = styled.div`
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 710px) {
    padding: 1.2rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #4f9deb;

    @media (max-width: 710px) {
      font-size: 18px;
    }
  }
`;

export const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #4f9deb;
  border-radius: 0.375rem;
  font-size: 14px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (max-width: 710px) {
    font-size: 12px;
    padding: 0.4rem 0.8rem;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0.5rem 0;
  border-bottom: 1px solid #4f9deb;

  @media (max-width: 710px) {
    padding: 0.5rem 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const DateFilterContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (min-width: 1500px) {
    gap: 3rem;
  }

  @media (max-width: 710px) {
    justify-content: space-between;
  }
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4, h5 {
    color: #4f9deb;

    @media (min-width: 1500px) {
      font-size: 1.3rem;
    }

    @media (max-width: 710px) {
      font-size: 0.9rem;
    }
  }
`;

export const MessageItem = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 1.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  border: 1px solid #4f9deb;

  @media (min-width: 1500px) {
    border-radius: 2rem;
    padding: 10px 30px;
  }

  @media (max-width: 710px) {
    padding: 8px;
    border-radius: 0.5rem;
  }
`;

export const MessageTimestamp = styled.p`
  font-size: 12px;
  color: #353535;

  @media (min-width: 1500px) {
    font-size: 1rem;
  }

  @media (max-width: 710px) {
    font-size: 10px;
  }
`;
