import styled from "styled-components";
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 3rem;
  border-radius: 2rem;
  max-width: 500px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;

  @media (min-width: 1500px) {
    height: 40vh;
    width: 40vw;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4rem;
    overflow-y: hidden;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ModalTitle = styled.h2`
  margin-bottom: 1.2rem;

  @media (min-width: 1500px) {
    font-size: 1.5rem;
  }
`;

export const TotalFiles = styled.p`
  color: #4f9deb;
  font-size: 12px;
  margin-top: 0;

  @media (min-width: 1500px) {
    font-size: 1.1rem;
  }
`;

export const FileListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0 2px;
  margin-bottom: 10px;
  font-weight: bold;

  @media (min-width: 1500px) {
    margin-bottom: 1rem;
    padding: 0 50px 0 20px;
  }
`;

export const FileList = styled.ul`
  list-style: none;
  padding: 0;

  @media (min-width: 1500px) {
    max-height: 70%;
  }
`;

export const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 1.5rem;

  @media (min-width: 1500px) {
    padding: 20px;
    border-radius: 1.8rem;
  }
`;

export const FileName = styled.span`
  color: #4f9deb;
  font-size: 11px;

  @media (min-width: 1500px) {
    font-size: 1.3rem;
  }
`;

export const FileActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FileDate = styled.span`
  font-size: 11px;

  @media (min-width: 1500px) {
    font-size: 1.3rem;
  }
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #4f9deb;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: red;
  }

  @media (min-width: 1500px) {
    font-size: 1.3rem;
    padding-left: 18px;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #4f9deb;
  cursor: pointer;
`;