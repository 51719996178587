import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ToggleCheckbox from "../common/ToggleCheckbox";
import FileUploadModal from "../FileUploadModal/FileUploadModal.jsx";
import fileApi from "../../services/api/fileApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileListModal from "../FileListModal/FileListModal.jsx";
import {
  faPlus,
  faChartBar,
  faEnvelope,
  faUpload,
  faList,
  faBars, // Icon for opening the sidebar
  faTimes, // Icon for closing the sidebar
} from "@fortawesome/free-solid-svg-icons";
import MaxxiLogo from "../../images/Logo_2024_Fundos_Claros.png";
import Mundo365 from "../../images/Mundo-365-Parceiro-Microsoft-do-Brasil.png";
import {
  ToggleContainer,
  SidebarButton,
  SidebarContainer,
  ButtonsContainer,
  LogosContainer,
  MobileMenuButton,
  Overlay,
} from "./Sidebar.js"; // Add necessary imports

const Sidebar = ({
  clientId,
  sessionId,
  onFileUpload,
  onNewChat,
  habilitaContexto,
  habilitaEmail,
  habilitaImagens,
  habilitaZendesk,
  onHabilitaImagens,
  onHabilitaEmail,
  onHabilitaZendesk,
  onHabilitaContexto,
  deleteNotification,
  isTyping,
}) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUploadOpen, setIsModaUploadlOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 

  const handleOpenDashboard = () => {
    navigate("/dashboard", { clientId, sessionId });
  };

  const handleDeleteNotification = (value) => {
    if (deleteNotification) {
      deleteNotification(value);
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await fileApi.getFiles(clientId);

      if (response.status !== 200) {
        throw new Error("Erro ao carregar arquivos");
      }

      const data = await response.data;
      const arrFiles = [];
      Object.entries(data).forEach(([file, date]) => {
        arrFiles.push({ file, date });
      });
      setFiles(arrFiles);
    } catch (error) {
      console.error("Erro ao carregar arquivos:", error);
    }
  };

  const handleFileUpload = async (files) => {
    if (onFileUpload) {
      onFileUpload(files);
    }
  };

  const handleNewChat = async () => {
    if (onNewChat) {
      onNewChat();
    }
  };

  const handleDeleteFile = async (fileId) => {
    const confirmDelete = window.confirm(
      "Você tem certeza que deseja deletar este arquivo?"
    );
    if (!confirmDelete) return;

    try {
      await fileApi.deleteFile(clientId, fileId);
      setFiles(files.filter((file) => file !== fileId));
      handleDeleteNotification("Arquivo excluído com sucesso!");
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
      alert("Ocorreu um erro ao deletar o arquivo.");
    }
  };

  const handleOpenMessageboard = () => {
    navigate("/messageboard", { clientId, sessionId });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    fetchFiles();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <MobileMenuButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {/* Toggle between menu and close icons */}
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="2x" />
      </MobileMenuButton>

      {isSidebarOpen && (
        <Overlay onClick={() => setIsSidebarOpen(false)} />
      )}

      <SidebarContainer isSidebarOpen={isSidebarOpen}>
        <ButtonsContainer>
          <SidebarButton onButtonClick={handleNewChat} disabled={isTyping}>
            <FontAwesomeIcon icon={faPlus} /> Novo chat
          </SidebarButton>
          <SidebarButton onButtonClick={handleOpenDashboard}>
            <FontAwesomeIcon icon={faChartBar} /> Abrir Dashboard
          </SidebarButton>
          <SidebarButton onButtonClick={handleOpenMessageboard}>
            <FontAwesomeIcon icon={faEnvelope} /> Mensagens
          </SidebarButton>
          <SidebarButton onButtonClick={() => setIsModaUploadlOpen(true)}>
            <FontAwesomeIcon icon={faUpload} /> Carregar arquivos
          </SidebarButton>
          <SidebarButton onButtonClick={handleOpenModal}>
            <FontAwesomeIcon icon={faList} /> Mostrar Arquivos
          </SidebarButton>
        </ButtonsContainer>
        <LogosContainer>
          <img src={MaxxiLogo} alt="Maxxi Logo" />
          <img src={Mundo365} alt="Mundo 365 Logo" />
        </LogosContainer>
        <div>
          <ToggleContainer>
            <ToggleCheckbox
              checked={habilitaImagens}
              onchange={onHabilitaImagens}
              label="Habilitar leitura de imagens"
            />
          </ToggleContainer>
          <ToggleContainer>
            <ToggleCheckbox
              checked={habilitaEmail}
              onchange={onHabilitaEmail}
              label="Habilitar envio e-mail"
            />
          </ToggleContainer>
          <ToggleContainer>
            <ToggleCheckbox
              checked={habilitaZendesk}
              onchange={onHabilitaZendesk}
              label="Habilitar Zendesk"
            />
          </ToggleContainer>
          <ToggleContainer>
            <ToggleCheckbox
              checked={habilitaContexto}
              onchange={onHabilitaContexto}
              label={habilitaContexto ? "Somente Contexto" : "Contexto + Internet"}
            />
          </ToggleContainer>
        </div>
        <FileUploadModal isOpen={isModalUploadOpen} onClose={() => setIsModaUploadlOpen(false)} onFileUpload={handleFileUpload} />
        <FileListModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          files={files}
          onDeleteFile={handleDeleteFile}
        />
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
