import React, { useState, useEffect, useRef } from "react";
import evaApi from "../../services/api/evaApi";
import Markdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot, faThumbsUp, faThumbsDown, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import EvaLogo from '../../images/EVA.png';
import { MenuDropdown,
  MainContainer,
  MenuContainer,
  MenuDropdownItem,
  MenuDropdownList,
  MenuIcon,
  Message,
  MessageText,
  SendButton,
  InputBox,
  NewChatButton,
  NewChatContainer,
  FeedbackIcons,
  ChatContainer,
  ChatMessages,
  Content,
  WidgetHeader,
  TextInput,
  TypingIndicator
} from "./ChatBotWidget";

const ChatBotWidget = ({ token, clientId, sessionId, onSessionId }) => {
  const [session, setSession] = useState("");
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [currentBotMessage, setCurrentBotMessage] = useState("");
  const [botTyping, setBotTyping] = useState(false);
  const [conversationEnded, setConversationEnded] = useState(false);
  const messagesEndRef = useRef(null); 
  const menuRef = useRef(null);
  const shouldLog = useRef(true);
  const [showMenu, setShowMenu] = useState(false);
  const [habilitaEmail, setHabilitaEmail] = useState(false);
  const [habilitaZendesk, setHabilitaZendesk] = useState(false);
  const [habilitaContexto, setHabilitaContexto] = useState(true);
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 10 * 60 * 1000;

  const resetTimer = () => {
    if (inactiveTimerRef.current) {
      clearTimeout(inactiveTimerRef.current);
    }
    inactiveTimerRef.current = setTimeout(() => {
      bye();
      setConversationEnded(true);
    }, inactivityLimit);
  };

  const handleFeedback = (messageIndex, rating) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      if (updatedMessages[messageIndex].sender === "bot") {
        updatedMessages[messageIndex].rating = rating;
      }
      return updatedMessages;
    });
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    resetTimer();
    return () => {
      clearTimeout(inactiveTimerRef.current);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const bye = async () => {
    setMessages([]);
    setIsTyping(true);

    const body = {
      query:
        "Se despeça de maneira informal para o usuário falando que por ter passado 10 min de atividade você encerrará o atendimento",
        memory: "{}",
        searchdocs: true,
        temperature: 0.1,
        template: habilitaContexto ? "template_contexto" : "template_internet",
        client_id: clientId,
        email: habilitaEmail,
        zendesk: habilitaZendesk,
        sessionid: session,
        username: `WIDGET {{${clientId}}}`,
        cl: process.env.REACT_APP_CL,
        engine:process.env.REACT_APP_ENGINE
    };

    const response = await evaApi.askai(body, token);
    const data = response.data;
    
    typeMessage(data.message);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentBotMessage]);

  useEffect(() => {
    if (session === "") {
      if (sessionId) {
        const fetchSession = async () => {
          try {
            const response = await evaApi.getSession(sessionId);
            const sessionData = await response.data.messages.sort((a, b) =>
              a.dat > b.data ? 1 : b.data > a.data ? -1 : 0
            );
            setSession(sessionId);
            setMessages([]);
            for (let i = 0; i < sessionData.length; i++) {
              const messageList = [];
              if (i === 0) {
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                  rating: null 
                });
              } else {
                messageList.push({
                  sender: "user",
                  text: sessionData[i]["pergunta"],
                });
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                  rating: null 
                });
              }
              setMessages((prevMessages) => [...prevMessages, ...messageList]);
            }
          } catch (error) {
            console.error("Error fetching session:", error);
          }
        };
        fetchSession();
      } else if (shouldLog.current) {
        shouldLog.current = false;

        const grettings = async () => {
          setMessages([]);
          setIsTyping(true);

          const body = {
            query:
              "Se apresente de maneira informal para o usuário falando sobre é um assistente virtual especializado da empresa e irá ajudá-lo",
            memory:
              '{"role": "system", "content": "Você é um representante comercial querendo vender um produto ou uma franquia. Lembre todas as perguntas que o humano fizer"}',
            searchdocs: false,
            temperature: 0.9,
            template: "template_saudacao",
            client_id: clientId,
            email: false,
            zendesk: false,
            sessionid: "",
            username: `WIDGET {{${clientId}}}`,
            cl: process.env.REACT_APP_CL,
            engine:process.env.REACT_APP_ENGINE
          };
    
          const response = await evaApi.askai(body, token);
          const data = await response.data;
          typeMessage(data.message);
          setSession(data.sessionid);
          onSessionId(data.sessionid);
        };

        grettings();
      }
    }
  }, [session, sessionId, clientId, token, onSessionId]);

  const handleNewChat = () => {
    shouldLog.current = true;
    setSession("");
    onSessionId("");
    setConversationEnded(false);
    setMessages([]);
    setInput("");
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "user", text: input };
    setMessages([...messages, userMessage]);

    if (input.toLowerCase() === "encerrar conversa") {
      setConversationEnded(true);
      setInput("");
      return;
    }

    setInput("");
    setIsTyping(true);
    
    const body = {
      query: input,
      memory: "{}",
      searchdocs: true,
      temperature: 0.1,
      template: habilitaContexto ? "template_contexto" : "template_internet",
      client_id: clientId,
      email: habilitaEmail,
      zendesk: habilitaZendesk,
      sessionid: session,
      username: `WIDGET {{${clientId}}}`,
      cl: process.env.REACT_APP_CL,
      engine: process.env.REACT_APP_ENGINE
    };

    const response = await evaApi.askai(body);
    const data = response.data;
    typeMessage(data.message);
  };

  const typeMessage = (message) => {
    setBotTyping(true);
    setCurrentBotMessage(""); // Limpa a mensagem atual do bot
    let index = -1;

    const typingInterval = setInterval(() => {
      setCurrentBotMessage((prev) => prev + message[index]);
      index++;

      if (index === message.length) {
        clearInterval(typingInterval);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: message, rating: null },
        ]);
        setBotTyping(false);
        setIsTyping(false);
      }
    }, 1);
  };

  return (
    <MainContainer>
      <WidgetHeader>
        <MenuContainer>
          <MenuIcon icon={faEllipsisV} onClick={toggleMenu} />
          {showMenu && (
            <MenuDropdown ref={menuRef}>
              <MenuDropdownList>
                <MenuDropdownItem onClick={() => { setConversationEnded(true); setShowMenu(false); }}>
                  Encerrar chat
                </MenuDropdownItem>
                <MenuDropdownItem onClick={() => { handleNewChat(); setShowMenu(false); }}>
                  Iniciar novo chat
                </MenuDropdownItem>
              </MenuDropdownList>
            </MenuDropdown>
          )}
        </MenuContainer>
        <div style={{ display: 'flex', gap: '5px' }}>
          <img src={EvaLogo} alt="eva logo" height='16px' />
          <h1 style={{fontSize: '16px'}}>Assistente</h1>
        </div>
      </WidgetHeader>
      <Content>
        <ChatContainer>
          <ChatMessages>
            {messages.map((message, index) => (
              <Message key={index} className={`message ${message.sender}`}>
                <FontAwesomeIcon
                  icon={message.sender === "user" ? faUser : faRobot}
                  className="message-icon"
                />
                <MessageText>
                  <Markdown>{message.text}</Markdown>
                  {message.sender === "bot" && (
                    <FeedbackIcons>
                      <FontAwesomeIcon
                        icon={faThumbsDown}
                        color="gray"
                        className={`thumb-icon ${message.rating === 0 ? "active" : ""}`}
                        onClick={() => handleFeedback(index, 0)}
                      />
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className={`thumb-icon ${message.rating === 1 ? "active" : ""}`}
                        onClick={() => handleFeedback(index, 1)}
                      />
                    </FeedbackIcons>
                  )}
                </MessageText>
              </Message>
            ))}
            {isTyping && !botTyping&& (
              <Message className="message bot">
                <FontAwesomeIcon icon={faRobot} className="message-icon" />
                <MessageText>
                  <TypingIndicator />
                </MessageText>
              </Message>
            )}
            {botTyping && (
              <Message className="message bot">
                <FontAwesomeIcon icon={faRobot} className="message-icon" />
                <MessageText>
                  <Markdown>{currentBotMessage}</Markdown>
                </MessageText>
              </Message>
            )}
            <div ref={messagesEndRef} />
          </ChatMessages>
        </ChatContainer>
      </Content>
      {!conversationEnded ? (
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', borderTop: '1px solid lightgray'}}>
          <span style={{marginTop: '3px'}}>Para encerrar o chat digite: 'Encerrar conversa'</span>
          <InputBox>
          <TextInput
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Digite sua mensagem..."
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          <SendButton onClick={handleSendMessage}>Enviar</SendButton>
        </InputBox>
           
        </div>
        
      ) : (
        <NewChatContainer>
          <NewChatButton onClick={handleNewChat}>Iniciar uma nova conversa</NewChatButton>
        </NewChatContainer>
      )}
    </MainContainer>
  );
};

export default ChatBotWidget;
